.email-statements-table {
  border-collapse: collapse;
  width: 100%;
}

.email-statements-table td, .email-statements-table th {
  text-align: left;
  vertical-align: top;
  padding: 10px;
}

.email-statements-table > tbody > tr:hover {
  background-color: yellow;
  cursor: pointer
}

.email-statements-normal-row {
  background-color: 'white'
}

.email-statements-selected-row {
  background-color: #f5f5f5;
}

.bold {
  font-weight: bold;
}

.selcol {
  background-color:#F5EFEF;
}

.selcol:hover {
  background-color:#ffff99;
}

.main {
  padding: 10px
}

.eob_detail_ins {
  background-color: rgb(242,242,255)
}

.eob_detail_pt {
  background-color: rgb(255,242,242)
}

.ledger_header {
  padding: 5px;
  margin-bottom: 3px;
  border: 1px solid lightgray;
  background-color: rgb(240,240,240);
}

.ledger-table tbody tr:hover {
  background-color: #ffff99
}

.ledger-table tbody tr:hover .highlighted-col {
  background-color: #ffff99
}

.recall-table tr:hover td {
  background: white !important;
}

.recall-table td {
    vertical-align: top;
}

.era-reconcile-table > tbody > tr:hover:not(.expanded-row) {
  background-color: #ffff99
}

.table-nohighlight tr:hover td {
  background: white !important;
}

.highlighted-col {
  text-align: right;
  background-color:#F5EFEF;
}

.patient_sider {
  background: white;
  border-left: 1px solid #e8e8e8;
}

.patient_content {
  background: white;
}

.policy-card {
  border: 1px solid lightgray;
  margin: 10px;
  padding: 10px;
  background-color: #F9F0EB;
}

.policy-card-date {
  color: rgb(53,191,51)
}

.balances {
  margin: 5px;
  padding-top:10px;
  padding-bottom:10px;
  border:1px solid lightgray;
}

.adjustments-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.recall-entry-modal .ant-legacy-form-item {
  margin-bottom: 3px;
}

.editlinedetails-table .ant-legacy-form-item {
  margin-bottom: 0px;
}

.columncolor1 {
  background: #EEE;
}

.services-list .ant-table-thead > tr > th {
  background-color: #F5F0F0 !important;
}

.service-selection-list .ant-table-thead > tr > th {
  background-color: #EBF5FA !important;
}

.service-loaded-list .ant-table-thead > tr > th {
  background-color: #FAEBEB !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.paymententry .ant-legacy-form-item {
  margin-bottom: 5px;
}

.inspayments .ant-table {
  font-size: 9pt;
}

.ptpayments .ant-table {
  font-size: 9pt;
}

.comment-list {
  padding-top: 20px;
  height: 400px;
  overflow-y:scroll;
}

.comment-entry {
  padding-bottom: 20px
}

.comment-byline {
  font-size: smaller;
  font-weight: bold;
}

.comment-text {
  padding-left: 5px;
}

.policy-form .ant-legacy-form-item {
  margin-bottom: 5px;
}

.cancelrow {
  text-decoration: line-through;
}

.cc-entry {
  background-color: rgb(246,249,252);
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.cc-entry label {
  color: #6b7c93;
  text-align: left;
  letter-spacing: 0.025em;
}

.cc-entry .error {
    color: #c23d4b;
    margin: 5px 0;
}

.cc-entry button {
    display: block;
    width: 100%;
    height: 37px;
    border-radius: 2px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

.cc-button {
  display: block;
  width: 100%;
  height: 37px;
  border-radius: 2px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.cc-entry input,.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    min-width: 200px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.credit-card-list {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 10px;
}

.payment-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.payer-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.tpa-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.ptpayments .ant-table-row-expand-icon-cell {
  width: 20px;
  min-width: 25px;
  text-align: center;
}

.sl_history_table tr {
  vertical-align: top;
}
