/* PADDING */
.p0 { padding: 0; }
.p1 { padding: .25rem; }
.p2 { padding: .50rem; }
.p3 { padding: .75rem; }
.p4 { padding: 1.00rem; }
.p5 { padding: 1.25rem; }
.p6 { padding: 1.50rem; }
.p7 { padding: 1.75rem; }
.p8 { padding: 2.00rem; }
/* PADDING TOP */
.pt0 { padding-top: 0; }
.pt1 { padding-top: .25rem; }
.pt2 { padding-top: .50rem; }
.pt3 { padding-top: .75rem; }
.pt4 { padding-top: 1.00rem; }
.pt5 { padding-top: 1.25rem; }
.pt6 { padding-top: 1.50rem; }
.pt7 { padding-top: 1.75rem; }
.pt8 { padding-top: 2.00rem; }
/* PADDING RIGHT */
.pr0 { padding-right: 0; }
.pr1 { padding-right: .25rem; }
.pr2 { padding-right: .50rem; }
.pr3 { padding-right: .75rem; }
.pr4 { padding-right: 1.00rem; }
.pr5 { padding-right: 1.25rem; }
.pr6 { padding-right: 1.50rem; }
.pr7 { padding-right: 1.75rem; }
.pr8 { padding-right: 2.00rem; }
/* PADDING BOTTOM */
.pb0 { padding-bottom: 0; }
.pb1 { padding-bottom: .25rem; }
.pb2 { padding-bottom: .50rem; }
.pb3 { padding-bottom: .75rem; }
.pb4 { padding-bottom: 1.00rem; }
.pb5 { padding-bottom: 1.25rem; }
.pb6 { padding-bottom: 1.50rem; }
.pb7 { padding-bottom: 1.75rem; }
.pb8 { padding-bottom: 2.00rem; }
/* PADDING LEFT */
.pl0 { padding-left: 0; }
.pl1 { padding-left: .25rem; }
.pl2 { padding-left: .50rem; }
.pl3 { padding-left: .75rem; }
.pl4 { padding-left: 1.00rem; }
.pl5 { padding-left: 1.25rem; }
.pl6 { padding-left: 1.50rem; }
.pl7 { padding-left: 1.75rem; }
.pl8 { padding-left: 2.00rem; }
/* MARGIN */
.m0 { margin: 0; }
.m1 { margin: .25rem; }
.m2 { margin: .50rem; }
.m3 { margin: .75rem; }
.m4 { margin: 1.00rem; }
.m5 { margin: 1.25rem; }
.m6 { margin: 1.50rem; }
.m7 { margin: 1.75rem; }
.m8 { margin: 2.00rem; }
/* PADDING TOP */
.mt0 { margin-top: 0; }
.mt1 { margin-top: .25rem; }
.mt2 { margin-top: .50rem; }
.mt3 { margin-top: .75rem; }
.mt4 { margin-top: 1.00rem; }
.mt5 { margin-top: 1.25rem; }
.mt6 { margin-top: 1.50rem; }
.mt7 { margin-top: 1.75rem; }
.mt8 { margin-top: 2.00rem; }
/* PADDING RIGHT */
.mr0 { margin-right: 0; }
.mr1 { margin-right: .25rem; }
.mr2 { margin-right: .50rem; }
.mr3 { margin-right: .75rem; }
.mr4 { margin-right: 1.00rem; }
.mr5 { margin-right: 1.25rem; }
.mr6 { margin-right: 1.50rem; }
.mr7 { margin-right: 1.75rem; }
.mr8 { margin-right: 2.00rem; }
/* PADDING BOTTOM */
.mb0 { margin-bottom: 0; }
.mb1 { margin-bottom: .25rem; }
.mb2 { margin-bottom: .50rem; }
.mb3 { margin-bottom: .75rem; }
.mb4 { margin-bottom: 1.00rem; }
.mb5 { margin-bottom: 1.25rem; }
.mb6 { margin-bottom: 1.50rem; }
.mb7 { margin-bottom: 1.75rem; }
.mb8 { margin-bottom: 2.00rem; }
/* PADDING LEFT */
.ml0 { margin-left: 0; }
.ml1 { margin-left: .25rem; }
.ml2 { margin-left: .50rem; }
.ml3 { margin-left: .75rem; }
.ml4 { margin-left: 1.00rem; }
.ml5 { margin-left: 1.25rem; }
.ml6 { margin-left: 1.50rem; }
.ml7 { margin-left: 1.75rem; }
.ml8 { margin-left: 2.00rem; }
/* TEXT ALIGNMENT */
.tal { text-align: left; }
.tar { text-align: right; }
.tac { text-align: center; }
/* WIDTHS & HEIGHTS */
.w100 { width: 100% }
.w50 { width: 50% }
.h100 { height: 100% }
.h50 { height: 50% }
/* VERTICAL ALIGNMENT */
.vat { vertical-align: top; }
.vab { vertical-align: bottom; }
.vam { vertical-align: middle; }