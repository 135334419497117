.notifications-table {
  border-collapse: collapse;
  width: 100%;
  margin-left: 5px;
}

.notifications-table td, .notifications-table th {
  text-align: left;
  vertical-align: top;
  padding: 0px;
}

.notifications-table > tbody > tr:hover {
  background-color: yellow;
  cursor: pointer
}

.notifications-normal-row {
  background-color: 'white'
}

.notifications-selected-row {
  background-color: #f5f5f5;
}

.session-list-item :hover {
  background-color: #ffffa1;
  cursor: pointer;
}

.schedule-session {
  margin-top: -20px
}

.chart-notes-table {
  border-collapse: collapse;
  width: 100%;
}

.chart-notes-table td, .chart-notes-table th {
  text-align: left;
  vertical-align: top;
  padding: 10px;
}

.chart-notes-table > tbody > .normal-row:hover,.selected-row:hover {
  background-color: #FFFF99;
  color: black;
  cursor: pointer
}

.normal-row {
  background-color: 'white'
}

.selected-row {
  background-color: #f5f5f5;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.expanded-note {
  background-color: #f5f5f5;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.pcpa-modal .ant-modal-body {
  padding: 0
}

.supervisor-table-row {
  vertical-align: top;
}

.newpatient-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.editpatient-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  float: left;
}

.comments tr:hover td {
  background: white !important;
}

.comments td {
    vertical-align: top;
}

.problemlist tr:hover td {
  background: white !important;
}

.problemlist td {
    vertical-align: top;
}

.active_problem {
  background-color: rgb(233,255,234);
  font-size: smaller;
}

.inactive_problem {
  background-color: rgb(255,233,233);
  font-size: smaller;
}

.chartnote {
  margin-bottom:50px;
}

.chartnote_datecolumn {
  width:150px;
  float:left;
}

.chartnote_notecolumn {
  margin-left:150px
}

.chartnote_dateformat {
  font-size:14pt;
  font-weight:bold;
}

.chartnote_timeformat {
  padding-left:10px;
}

.chartnote_ms {
  margin-left: 0em;
}

.chartnotetitle {
  margin:0px;
  text-decoration:underline;
  font-weight: bold;
  font-size: 110%;
}

.chartnotesignature {
  font-style:italic;
  margin-top:10px;
}

.chartnotescosignature {
  font-style:italic;
}

.chartnotefield {
  margin:0px;
}

.chartnotefieldlabel {
  font-weight: bold;
}

.chartnote_sup_comment {
  margin: 20px;
}

.chartnote_sup_comment > span {
  border: 1px solid red;
  padding: 10px;
  color: red;
}

.chartnote_supervisor_review {
  border: 1px solid lightgray;
  background-color: rgb(250,250,250);
  border-radius: 3px;
  padding: 10px;
  margin: 20px 20px 20px 0px;
}

.chartnote_addendum {
  border: 1px solid lightgray;
  background-color: rgb(230, 242, 255);
  border-radius: 3px;
  padding: 10px;
  margin: 20px 20px 20px 0px;
  width: 80%;
}

.chartnote_addendum_item {
  margin-bottom: 20px;
}

.status_show {
  color:green;
}

.status_noshow {
  color:red;
}

.status_latecancel {
  color:red;
}

#hfnote * {
	margin:				0;
	padding:			0;
}

#hfnote h1 {
	border-bottom: 1px solid #000;
	font-size: 18px;
	padding-top: 10px;
}

#hfnote h2 {
	padding-top: 10px;
	font-size: 16px;
}

#hfnote h3 {
	padding-top: 10px;
	font-size: 14px;
}

#hfnote th {
	vertical-align:		middle;
	text-align:			left;
}

#hfnote table.dataTable {
	width: 100%;
	border-spacing: 0px;
}

#hfnote th {
	background-color: #e8e8e8;
	border-bottom: 1px solid #000;
}

.fltL {
	float:				left;
}
.fltR {
	float:				right;
}
.clr {
	clear:				both;
}

.encHeaderInfoCol1 label {
	display: inline-block;
	text-align: right;
	vertical-align: top;
    width: 25%;
}

.encHeaderInfoCol1 {
    display: inline-block;
	vertical-align: top;
	width: 33%;
}

.encHeaderInfoCol1 p {
	display: inline-block;
	vertical-align: top;
	width: 70%;
}
.encHeaderInfoCol2 label {
	display: inline-block;
	text-align: right;
    width: 33%;
	vertical-align: top;
}
.encHeaderInfoCol2 {
	display: inline-block;
	vertical-align: top;
	width: 33%;
}
.encHeaderInfoCol2 p {
	box-sizing: border-box;
    display: inline-block;
    padding-left: 5px;
	vertical-align: top;
	width: 64%;
}
.encHeaderInfoCol3 label {
	width: 48%;
	text-align: right;
	display: inline-block;
	vertical-align: top;
}
.encHeaderInfoCol3 {
	width: 33%;
	display: inline-block;
	vertical-align: top;
}
.encHeaderInfoCol3 p {
	width: 50%;
	display: inline-block;
	vertical-align: top;
}

.ant-drawer-body {
  padding: 0px !important;
}

.foobar {
  padding: 0px;
  margin: 0px;
}
