.statement-table {
  border: 1px solid lightgray;
}

.statement-table tr:nth-child(even) {
  background: #EEE
}

.statement-table th {
  background: lightgray;
  border-right: 1px solid lightgray;
}

.statement-table tr:nth-child(odd) {
  background: #FFF
}

.statement-table td {
  padding: 5px;
  border-right: 1px solid lightgray;
}
