.balances-table {
  margin-left:auto;
  margin-right:auto;
}

.balances-table > td, .balances-table > th {
  text-align:right;
  vertical-align:middle;
}

.adjustments-table {
  margin-left:auto;
  margin-right:auto;
  width: 400px;
}

.adjustments-table > td, .adjustments-table > th {
  text-align: right;
  padding: 5px;
  margin: 5px;
}
