.history-table-row {
  vertical-align: top;
}

.nolinehighlight-table tbody td:hover {
  background-color: #ffffff
}

.provider-list {
  width:100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.provider-list tr {
  cursor: pointer;
}

.provider-list .selected {
  background: lightgray;
}

.compressed-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.compressed-form .ant-form-explain {
  display:none;
}

.template-chooser {
  margin-top:5px;
  width: 100%;
}

.template-chooser tr {
  cursor: pointer;
}

.template-chooser .template_selected {
  background: lightgray;
}

.template-chooser .template_title {
  font-size: smaller;
  color: gray;
}

.scheduler-tabs > .ant-tabs-content {
  height: 100%;
}

.template-calendar-table {
  width: 100%;
}

.template-calendar-table th {
  text-align: center;
}

.template-item {
  width:100%;
  border:1px solid black;
  background:#FFDC00;
  padding:10px;
  cursor: pointer;
  margin-bottom: 5px;
}

.session-item {
  width:100%;
  border:1px solid black;
  background:#DDDDDD;
  margin-bottom: 5px;
  border-radius: 3px;
}
