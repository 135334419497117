html,
body {
  margin: 0;
  height: 100vh;
  color: rgba(0,0,0,0.65);
}

#root {
  height: 100vh;
}

#login-wrapper {
  margin: auto;
  padding-top: 15vh;
  width: 400px;
}

#login {
  padding: 15px;
  border: 1px solid #D9D9D9;
  background-color: #FAFAFA;
  border-radius: 10px;
}

.no-wrap {
  white-space: nowrap;
}

.ant-legacy-form-explain {
  color: red;
}