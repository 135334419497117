.reports-sessionlist-table .ant-table td {
  white-space: nowrap;
}

.reports-sessionlist-table .ant-table th {
  white-space: nowrap;
}

.red-row > td:nth-child(5) {
  color: #ff3939;
  font-weight: bold;
}

.blue-row > td:nth-child(5) {
  color: rgb(61, 84, 254);
  font-weight: bold;
}