.org-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.locations-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.carriers-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.cpt-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.ratetable-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.users-form .ant-legacy-form-item {
  margin-bottom: 0px;
}

.groups-table td {
  vertical-align: top;
}
