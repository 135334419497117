.fc {
    direction:ltr;
    text-align:left;
}

.fc th {
    text-align:center
}

.fc-event.fc-draggable,.fc-event[href],.fc-popover .fc-header .fc-close,a[data-goto]{
    cursor:pointer
}

.fc-bg table,.fc-row .fc-bgevent-skeleton table,.fc-row .fc-highlight-skeleton table{
    height:100%
}
.fc-clear{
    clear:both
}
.fc-bg,.fc-bgevent-skeleton,.fc-helper-skeleton,.fc-highlight-skeleton{
    position:absolute;
    top:0;
    left:0;
    right:0
}
.fc-bg{
    bottom:0
}
.fc table{
    width:100%;
    box-sizing:border-box;
    table-layout:fixed;
    border-collapse:collapse;
    border-spacing:0
}
.fc td,.fc th{
    border-style:solid;
    border-width:1px;
    padding:0;
    vertical-align:top
}
.fc td.fc-today{
    border-style:double
}
a[data-goto]:hover{
    text-decoration:underline
}
.fc .fc-row{
    border-style:solid;
    border-width:0
}
.fc-row table{
    border-left:0 hidden transparent;
    border-right:0 hidden transparent;
    border-bottom:0 hidden transparent
}
.fc-row:first-child table{
    border-top:0 hidden transparent
}
.fc-row{
    position:relative
}
.fc-row .fc-bg{
    z-index:1
}
.fc-row .fc-bgevent-skeleton,.fc-row .fc-highlight-skeleton{
    bottom:0
}
.fc-row .fc-bgevent-skeleton td,.fc-row .fc-highlight-skeleton td{
    border-color:transparent
}
.fc-row .fc-bgevent-skeleton{
    z-index:2
}
.fc-row .fc-highlight-skeleton{
    z-index:3
}
.fc-row .fc-content-skeleton{
    position:relative;
    z-index:4;
    padding-bottom:2px
}
.fc-row .fc-helper-skeleton{
    z-index:5
}
.fc .fc-row .fc-content-skeleton table,.fc .fc-row .fc-content-skeleton td,.fc .fc-row .fc-helper-skeleton td{
    background:0 0;
    border-color:transparent
}
.fc-row .fc-content-skeleton td,.fc-row .fc-helper-skeleton td{
    border-bottom:0
}
.fc-row .fc-content-skeleton tbody td,.fc-row .fc-helper-skeleton tbody td{
    border-top:0
}
.fc-scroller{
    -webkit-overflow-scrolling:touch
}
.fc-icon,.fc-row.fc-rigid,.fc-time-grid-event{
    overflow:hidden
}
.fc-scroller>.fc-day-grid,.fc-scroller>.fc-time-grid{
    position:relative;
    width:100%
}
.fc-event{
    position:relative;
    display:block;
    font-size:.85em;
    line-height:1.3;
    border: 1px solid white;
    margin-bottom: 3px;
}
.fc-event,.fc-event-dot{
    background-color:#3a87ad
}
.fc-event,.fc-event:hover{
    color:#fff;
    text-decoration:none
}
.fc-not-allowed,.fc-not-allowed .fc-event{
    cursor:not-allowed
}
.fc-event .fc-bg{
    z-index:2;
    background:#fff;
    opacity:.25
}
.fc-event .fc-content{
    position:relative;
    z-index:2
}
.fc-event .fc-resizer{
    position:absolute;
    z-index:4;
    display:none
}
.fc-event.fc-allow-mouse-resize .fc-resizer,.fc-event.fc-selected .fc-resizer{
    display:block
}
.fc-event.fc-selected .fc-resizer:before{
    content:"";
    position:absolute;
    z-index:9999;
    top:50%;
    left:50%;
    width:40px;
    height:40px;
    margin-left:-20px;
    margin-top:-20px
}
.fc-event.fc-selected{
    z-index:9999!important;
    box-shadow:0 2px 5px rgba(0,0,0,.2)
}
.fc-event.fc-selected.fc-dragging{
    box-shadow:0 2px 7px rgba(0,0,0,.3)
}

.fc-now-indicator{
    position:absolute;
    border:0 solid red
}

.fc-unthemed .fc-content,.fc-unthemed .fc-divider,.fc-unthemed .fc-list-heading td,.fc-unthemed .fc-list-view,.fc-unthemed .fc-popover,.fc-unthemed .fc-row,.fc-unthemed tbody,.fc-unthemed td,.fc-unthemed th,.fc-unthemed thead{
    border-color:#ddd
}
.fc-unthemed .fc-divider,.fc-unthemed .fc-list-heading td,.fc-unthemed .fc-popover .fc-header{
    background:#eee
}
.fc-unthemed td.fc-today{
    background:#fcf8e3
}

.fc-unthemed td.fc-holiday{
  background:plum;
}

.fc-unthemed td.fc-pto {
  background:orange;
}

.fc-unthemed td.fc-uto {
  background:pink;
}

.fc-unthemed .fc-list-item:hover td{
    background-color:#f5f5f5
}
.fc-view-container *,.fc-view-container :after,.fc-view-container :before{
    box-sizing:content-box
}
.fc-scroller-canvas,.tether-element,.tether-element *,.tether-element :after,.tether-element :before,.tether-element:after,.tether-element:before{
    box-sizing:border-box
}
.fc-row.fc-rigid .fc-content-skeleton{
    position:absolute;
    top:0;
    left:0;
    right:0
}
.fc .fc-axis{
    vertical-align:middle;
    padding:0 4px;
    white-space:nowrap
}
.fc-ltr .fc-axis{
    text-align:right
}
.fc-rtl .fc-axis{
    text-align:left
}
.fc-time-grid,.fc-time-grid-container{
    position:relative;
    z-index:1
}
.fc-time-grid{
    min-height:100%
}
.fc-time-grid table{
    border:0 hidden transparent
}
.fc-time-grid>.fc-bg{
    z-index:1
}
.fc-time-grid .fc-slats,.fc-time-grid>hr{
    position:relative;
    z-index:2
}
.fc-time-grid .fc-content-col{
    position:relative
}
.fc-time-grid .fc-content-skeleton{
    position:absolute;
    z-index:3;
    top:0;
    left:0;
    right:0
}
.fc-time-grid .fc-business-container{
    position:relative;
    z-index:1
}
.fc-time-grid .fc-bgevent-container{
    position:relative;
    z-index:2
}
.fc-time-grid .fc-highlight-container{
    z-index:3;
    position:relative
}
.fc-time-grid .fc-event-container{
    position:relative;
    z-index:4
}
.fc-time-grid .fc-now-indicator-line{
    z-index:5
}
.fc-time-grid .fc-helper-container{
    position:relative;
    z-index:6
}
.fc-time-grid .fc-slats td{
    height:1.5em;
    border-bottom:0
}
.fc-time-grid .fc-slats .fc-minor td{
    border-top-style:none;
}
.fc-time-grid .fc-highlight{
    position:absolute;
    left:0;
    right:0
}
.fc-ltr .fc-time-grid .fc-event-container{
    margin:0 2.5% 0 2px
}
.fc-rtl .fc-time-grid .fc-event-container{
    margin:0 2px 0 2.5%
}
.fc-time-grid .fc-bgevent,.fc-time-grid .fc-event{
    position:absolute;
    z-index:1
}
.fc-time-grid .fc-bgevent{
    left:0;
    right:0
}
.fc-v-event.fc-not-start{
    border-top-width:0;
    padding-top:1px;
    border-top-left-radius:0;
    border-top-right-radius:0
}
.fc-v-event.fc-not-end{
    border-bottom-width:0;
    padding-bottom:1px;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0
}
.fc-time-grid-event.fc-selected{
    overflow:visible
}
.fc-scroller-clip,.fc-time-grid-event .fc-content,.fc-timeline .fc-cell-content,.fc-timeline-event .fc-content,tr.fc-collapsed>td,tr.fc-transitioning>td{
    overflow:hidden
}
.fc-time-grid-event.fc-selected .fc-bg{
    display:none
}
.fc-time-grid-event .fc-time,.fc-time-grid-event .fc-title{
    padding:0 1px
}
.fc-time-grid-event .fc-time{
    font-size:.85em;
    white-space:nowrap
}
.fc-time-grid-event.fc-short .fc-content{
    white-space:nowrap
}
.fc-time-grid-event.fc-short .fc-time,.fc-time-grid-event.fc-short .fc-title{
    display:inline-block;
    vertical-align:top
}
.datepicker .cw,.fc-list-empty,.fc-timeline th,.input-daterange .add-on{
    vertical-align:middle
}
.fc-time-grid-event.fc-short .fc-time span{
    display:none
}
.fc-time-grid-event.fc-short .fc-time:before{
    content:attr(data-start)
}
.fc-time-grid-event.fc-short .fc-time:after{
    content:"\A0-\A0"
}
.fc-time-grid-event.fc-short .fc-title{
    font-size:.85em;
    padding:0
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer{
    left:0;
    right:0;
    bottom:0;
    height:8px;
    overflow:hidden;
    line-height:8px;
    font-size:11px;
    font-family:monospace;
    text-align:center;
    cursor:s-resize
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after{
    content:"="
}
.fc-time-grid-event.fc-selected .fc-resizer{
    border-radius:5px;
    border-width:1px;
    width:8px;
    height:8px;
    border-style:solid;
    border-color:inherit;
    background:#fff;
    left:50%;
    margin-left:-5px;
    bottom:-5px
}
.fc-time-grid .fc-now-indicator-line{
    border-top-width:1px;
    left:0;
    right:0
}
.fc-time-grid .fc-now-indicator-arrow{
    margin-top:-5px
}
.fc-ltr .fc-time-grid .fc-now-indicator-arrow{
    left:0;
    border-width:5px 0 5px 6px;
    border-top-color:transparent;
    border-bottom-color:transparent
}

.fc-event.fc-event-show{
    background-color:#c0f2d6;
    color:#395646
}
.fc-event.fc-event-show a{
    color:#395646
}

.fc-event.fc-event-signed {
  background-color:lightgray;
  color:#888888
}

.fc-event.fc-event-pto{
  background: orange;
}

.fc-event.fc-event-admin{
  background: purple;
}

.fc-event.fc-event-latecancel {
  background: #ff3333;
  color: white;
}

.fc-event.fc-event-uto{
  background: pink;
}

.fc-event.fc-event-blackout {
  background: gray;
}

.fc-event.fc-event-signed a{
  color:#EEEEEE
}

.fc-event.fc-event-opentime {
  background-color:yellow;
  border: 1px solid gray;
  color:black;
}

.fc-event.fc-event-intake {
  background: repeating-linear-gradient(
    135deg,
    #3a87ad,
    #3a87ad 10px,
    #619fbd 10px,
    #619fbd 20px
  );
}
